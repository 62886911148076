.game-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, 1fr);
  padding: 5px;
  gap: 10px;
}

@media (min-width: 600px) {
  .game-list {
    grid-template-columns: repeat(auto-fill, 400px);
    padding: 10px;
  }
}

.game-card {
  border: 2px solid var(--color-brand);
  border-radius: 6px;
  padding: 5px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 30px 30px auto;
  grid-template-areas:
    'type date date'
    'summary summary summary'
    'link . delete';
  max-width: 400px;
}

.game-card__summary {
  grid-area: summary;
}

.game-card__type {
  font-weight: bold;
  grid-area: type;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.game-card__type strong {
  font-weight: bold;
  display: inline-block;
  margin-left: 6px;
}

.game-card__date {
  grid-area: date;
  text-align: right;
}

.game-card__link-button {
  grid-area: link;
  text-decoration: none;
}

.game-card__full-button {
  grid-area: link / span 3;
}

.game-card__delete-button {
  grid-area: delete;
  color: red !important;
  border-color: red !important;
}
