:root {
  --color-brand: #279ef5;
  --scribble-font-family: 'Rock Salt', cursive;
  --scribble-font-size: 0.9em;
}

.app {
  border: 1px solid black;
  border-radius: 10px;
  display: grid;
  grid-template-rows: 40px auto;
  min-height: 98vh;
  margin: 2px;
  padding-bottom: 10px;
}

#homepage h1 {
  margin: 0 0 12px 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

#homepage h2 {
  padding: 8px;
  margin: 0;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid black;
  padding: 0 6px;
}

.header h1 {
  display: inline;
  margin: 0;
  padding: 0;
}

.no-content {
  min-height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.scoresheet {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  margin: 2px;
}

@media (min-width: 768px) {
  .scoresheet {
    grid-template-columns: repeat(auto-fill, 130px);
  }
}

.section-divider {
  height: 26px;
  display: flex;
  align-items: center;
  font-weight: bold;
}

.cell {
  height: 40px;
  border: solid 1px black;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0 6px;
}

.instructions {
  justify-content: center;
  text-align: center;
  font-size: 0.9em;
}

.cell .die-label {
  display: inline-block;
  width: 50px;
}

.cell svg {
  font-size: 1.6em;
  margin: 0 4px;
}

.score-cell {
  justify-content: center;
  font-family: var(--scribble-font-family);
  font-size: var(--scribble-font-size);
}

.input-cell {
  padding: 0;
}

.input-cell input {
  width: 100%;
  text-align: center;
  font-family: var(--scribble-font-family);
  font-size: var(--scribble-font-size);
}

.player-name {
  justify-content: space-around;
  font-family: var(--scribble-font-family);
  font-size: var(--scribble-font-size);
}

.cell.disabled svg {
  color: #ccc;
}

.btn {
  background-color: var(--color-brand);
  color: white;
  border: 1px solid var(--color-brand);
  padding: 5px;
  border-radius: 6px;
  text-align: center;
  cursor: pointer;
}

.btn.outline {
  background-color: white;
  color: var(--color-brand);
  border: 1px solid var(--color-brand);
}

/* 
font testing
    <link
      href="https://fonts.googleapis.com/css?family=Architects+Daughter|Patrick+Hand|Permanent+Marker|Rock+Salt|Schoolbell&display=swap"
      rel="stylesheet"
    />

.font-marker {
  font-family: 'Permanent Marker', cursive;
}
.font-patrick {
  font-family: 'Patrick Hand', cursive;
}
.font-architect {
  font-family: 'Architects Daughter', cursive;
}
.font-rocksalt {
  font-family: 'Rock Salt', cursive;
}
.font-schoolbell {
  font-family: 'Schoolbell', cursive;
} */
